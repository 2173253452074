import React, { FunctionComponent, useMemo } from 'react';
import clsx from 'clsx';
import { graphql, Link, useStaticQuery } from 'gatsby';
import loadable from '@loadable/component';
import Img from 'gatsby-image';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
//styles
import styles from './saas.module.css';
//components
import Solutions from './solutions';
import Integrations from './integrations';
import Quotes from './quotes';
import Recognition from '../../recognition';
import Blockquote from '../components/blockquote';
import Booking from '../../feedback';
import { Condogenie, EveryPig, LadderOut, Adact, Heypractice } from '../../carousel';
import { Routes as routes } from '../../routes';
import Layout from '../components/layout';
//types
import { ImageFluid } from '../../Types';
//assets
import widetext from '../../../images/saas/widetext.svg';
import heypractice from '../../../images/saas/heypractice.png';
import arrow from '../../../images/saas/arrow.svg';
import widetextMobile from '../../../images/saas/widetext_mobile.svg';
import bigWidetext from '../../../images/saas/big_widetext.svg';
import bigWidetextMobile from '../../../images/saas/big_widetext_mobile.svg';
//meta
import { meta } from '../../../metaData';
import identificators from '../../googleAnalyticsIdentificators';

const SwiperSlider = loadable(() => import('components/swiper-slider'));

type Point = {
  image: ImageFluid;
  text: string;
};

type GraphItem = {
  image: ImageFluid;
  text: string;
  title: string;
  link: string;
  linkText: string;
};

interface Props {
  location: Location;
}
const SaasDevelopmentServices: FunctionComponent<Props> = ({ location }) => {
  const breakpoint = useBreakpoint();
  const data = useStaticQuery(graphql`
    query {
      david: file(relativePath: { eq: "saas/david.png" }) {
        childImageSharp {
          fluid(maxWidth: 215, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      deep: file(relativePath: { eq: "saas/deep.png" }) {
        childImageSharp {
          fluid(maxWidth: 350, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      code: file(relativePath: { eq: "saas/code.png" }) {
        childImageSharp {
          fluid(maxWidth: 350, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      timeline: file(relativePath: { eq: "saas/timeline.png" }) {
        childImageSharp {
          fluid(maxWidth: 350, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      time: file(relativePath: { eq: "saas/time.png" }) {
        childImageSharp {
          fluid(maxWidth: 350, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      refresh: file(relativePath: { eq: "saas/refresh.png" }) {
        childImageSharp {
          fluid(maxWidth: 350, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      people: file(relativePath: { eq: "saas/people.png" }) {
        childImageSharp {
          fluid(maxWidth: 350, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      mvp: file(relativePath: { eq: "saas/mvp.png" }) {
        childImageSharp {
          fluid(maxWidth: 500, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      product: file(relativePath: { eq: "saas/product.png" }) {
        childImageSharp {
          fluid(maxWidth: 500, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      scale: file(relativePath: { eq: "saas/scale.png" }) {
        childImageSharp {
          fluid(maxWidth: 500, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  const pointsList: Point[] = useMemo(
    () => [
      {
        image: data.deep.childImageSharp.fluid,
        text: 'Deep knowledge of SaaS technology',
      },
      {
        image: data.code.childImageSharp.fluid,
        text: 'Exceptional coding and UI/UX skills',
      },
      {
        image: data.timeline.childImageSharp.fluid,
        text: 'Quick-paced, timeline focused workflow',
      },
      {
        image: data.time.childImageSharp.fluid,
        text: 'We perfectly adjust to your work hours',
      },
      {
        image: data.refresh.childImageSharp.fluid,
        text: 'Frequent updates, regular reporting',
      },
      {
        image: data.people.childImageSharp.fluid,
        text: 'Trained to work together effectively',
      },
    ],
    []
  );

  const graphs: GraphItem[] = useMemo(
    () => [
      {
        image: data.mvp.childImageSharp.fluid,
        text: 'Build a prototype or an MVP, test it with end-users and improve based on their feedback. We provide a cross-functional team to implement your product vision following the lean startup methodology.',
        title: 'Achieve product/market fit the Lean way',
        link: routes.SERVICES_MVP_DEVELOPMENT,
        linkText: 'MVP development services',
      },
      {
        image: data.product.childImageSharp.fluid,
        text: 'Improve your existing SaaS software product,  add new features, maintain and support the system with an expert development team ready to take accountability for the whole project or part of it.',
        title: 'Scale your SaaS and build a world-class product',
        link: routes.SERVICES_PRODUCT_DEVELOPMENT,
        linkText: 'Product development services',
      },
      {
        image: data.scale.childImageSharp.fluid,
        text: 'Augment your team with our qualified SaaS app developers. Find the skills and expertise you currently lack ‒ Brocoders can fill your talent gap with required engineers to work alongside your in-house team.',
        title: 'Hire required engineers to pivot or grow with confidence',
        link: routes.SERVICES_TEAM_AUGMENTATION,
        linkText: 'Team augmentation services',
      },
    ],
    []
  );

  return (
    <Layout
      title="Set Up a Skilled SaaS Development Team and Start Your Project Instantly"
      subtitle="Build a committed team or fill the required positions with the right talent — Brocoders has professional developers to start your SaaS project without delays."
      metaData={{ main: meta.saasDevelopmentServices, faq: [] }}
      pathname={location.pathname}
      withMovie
      getInTouchTextColor="#000"
    >
      <section className={clsx('section', styles.mvp)}>
        <div className="inner">
          <div className={styles.points}>
            {pointsList.map((item: Point, idx: number) => (
              <div className={styles.point} key={idx}>
                <Img
                  fluid={item.image}
                  className={styles.pointImg}
                  draggable={false}
                  alt={`saas-point-${idx}`}
                />
                <p>{item.text}</p>
              </div>
            ))}
          </div>
        </div>
      </section>
      <section className={clsx('section', styles.containerOffer)}>
        <div className="inner">
          <div className={styles.wrapperOffer}>
            {breakpoint.s ? (
              <img
                draggable={false}
                src={widetextMobile}
                className={styles.offerIcon}
                alt="offer icon"
              />
            ) : (
              <img draggable={false} src={widetext} className={styles.offerIcon} alt="offer icon" />
            )}
            <div className={styles.offerBox}>
              <Link
                id={identificators.START_PROJECT}
                className={clsx('btn btn_60 btn__black', styles.wideLink, styles.linkOffer)}
                to="/get-in-touch/"
              >
                Start your project
              </Link>
            </div>
          </div>
        </div>
      </section>
      <section className="section">
        <div className="inner">
          <h3 className={clsx('title', styles.smallTitle)}>Struggling to find a committed team?</h3>
          <p className={clsx('subtitle', styles.smallSubtitle)}>
            Setting up a committed team is a huge challenge. By committed we mean a team that puts
            the customer needs first, knows how to solve technical challenges, and focuses on
            delivering value. Brocoders have all of these characteristics.{' '}
          </p>
        </div>
        <div className="inner">
          <h2 className="title">
            Think of Brocoders as your remote SaaS development team, not a subcontractor
          </h2>
          <p className="subtitle">
            We are the A-Team, a group of SaaS software developers with the right mix of skills,
            abilities, and perspectives. Here is what we can achieve together:
          </p>
        </div>
      </section>

      <Quotes />

      <section className={styles.carousel}>
        <div className="inner">
          <h2 className={clsx('title', styles.sliderTitle)}>Look at our latest work</h2>
        </div>
        <SwiperSlider>
          <LadderOut />
          <EveryPig />
          <Condogenie />
          <Adact />
          <Heypractice />
        </SwiperSlider>
      </section>

      <section className="section">
        <div className="inner">
          <h2 className="title">We mesh perfectly well with your in-house team</h2>
          <p className="subtitle">
            Develop a functional MVP, enhance your product, or get expert developers to strengthen
            your team – we offer three types of collaboration to fit the needs of SaaS product
            owners.
          </p>

          <div className={styles.graphs}>
            {graphs.map((item: GraphItem, idx: number) => (
              <div key={idx} className={styles.graphWrapper}>
                <div className={styles.graphContent}>
                  <h3 className={clsx('title', styles.quoteTitle)}>{item.title}</h3>
                  <p className={clsx('subtitle', styles.quoteSubtitle)}>{item.text}</p>

                  <Link to={item.link} className={styles.graphLink}>
                    {item.linkText}
                  </Link>
                </div>
                <div className={styles.graphImageWrapper}>
                  <Img fluid={item.image} className={styles.graphImage} alt={item.title} />
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      <Solutions />
      <Integrations />
      <section className={clsx('section', styles.gray, styles.grayWave)}>
        <div className="inner">
          <h2 className={clsx('title', styles.reviewTitle)}>Brocoders are exactly who you need</h2>
          <Blockquote
            img={data.david.childImageSharp.fluid}
            isRating
            isLogo={true}
            logoImg={heypractice}
            logoStyles={styles.quoteReviewLogo}
            blockquoteClassName={styles.bqInner}
            text={`“With Brocoders, we were able to set up a highly skilled IT team in the shortest amount of time. For finding a team with similar skills we would have needed 4-6 months instead of the instant start.”`}
            author={
              <React.Fragment>
                <strong>David Neuendorf</strong> <br />
                CEO,{' '}
                <a href="https://heypractice.com/" target="_blank" className={styles.red}>
                  HeyPractice.com
                </a>
              </React.Fragment>
            }
          />
        </div>
      </section>

      <section className="section">
        <div className="inner">
          <h2 className="title">Recognition</h2>
          <Recognition />
        </div>
      </section>

      <section className={clsx('section', styles.containerOffer)}>
        <div className="inner">
          <div className={styles.wrapperOffer}>
            {breakpoint.s ? (
              <img src={bigWidetextMobile} draggable={false} className={styles.offerIcon} alt="" />
            ) : (
              <img
                src={bigWidetext}
                draggable={false}
                className={clsx(styles.offerIcon, styles.bigOfferIcon)}
                alt=""
              />
            )}
            <div className={styles.offerBox}>
              <img src={arrow} draggable={false} className={styles.offerArrow} alt="offer arrow" />
              <Link
                id={identificators.CONTACT_US}
                className={clsx('btn btn_60 btn__black', styles.wideLink, styles.linkOffer)}
                to="/get-in-touch/"
              >
                Contact us
              </Link>
            </div>
          </div>
        </div>
      </section>

      <Booking />
    </Layout>
  );
};

export default SaasDevelopmentServices;
