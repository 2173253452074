import React, { FunctionComponent, useState, ReactElement, useLayoutEffect } from 'react';
import clsx from 'clsx';
import arrow from './arrow.svg';
import styles from './expandinglist.module.css';

export type ListItem = {
  text: string;
  content: ReactElement;
};

type Props = {
  className?: string;
  list: ListItem[];
};
const ExpandingList: FunctionComponent<Props> = ({ className, list }) => {
  const [opened, onChangeOpened] = useState<number>(0);
  const [canUseScroll, setCanUseScroll] = useState<boolean>(false);

  const listRef = [...Array(list.length)].map((r) => React.createRef<HTMLLIElement>());

  useLayoutEffect(() => {
    if (canUseScroll && listRef[opened] && listRef[opened].current) {
      listRef[opened].current?.scrollIntoView({
        block: 'start',
      });
    }
  }, [canUseScroll, opened]);

  const handleTogglerClick = (index: number) => {
    onChangeOpened(index), setCanUseScroll(true);
  };

  return (
    <div className={clsx(styles.wrapper, className)}>
      <ul className={styles.list}>
        {list.map((item: ListItem, idx: number) => (
          <li
            key={idx}
            className={clsx(styles.item, { [styles.open]: opened === idx })}
            ref={listRef[idx]}
          >
            <button
              className={styles.toggler}
              onClick={() => handleTogglerClick(idx)}
              role="button"
            >
              {item.text}
              {opened !== idx ? (
                <img
                  className={styles.arrow}
                  src={arrow}
                  alt="expanding-list-arrow"
                  draggable={false}
                />
              ) : null}
            </button>
            <div className={styles.contentWrapper}>{item.content}</div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ExpandingList;
