import React, { FunctionComponent, ReactElement, useMemo } from 'react';
import styles from './saas.module.css';
import clsx from 'clsx';
import Blockquote from '../../cases/components/blockquote';
import { ImageFluid } from '../../Types';
import { graphql, useStaticQuery } from 'gatsby';

import truck4goods from '../../../images/truck4goods-logo.png';
import condogenie from '../../../images/condogenie-logo.png';
import adact from '../../../images/adact/logo.png';

type QuoteItem = {
  logo: string;
  title: string;
  company: string;
  subtitle: string;
  text: string;
  img: ImageFluid;
  author: ReactElement;
};
const Quotes: FunctionComponent = () => {
  const data = useStaticQuery(graphql`
    query {
      kalev: file(relativePath: { eq: "saas/kalev.png" }) {
        childImageSharp {
          fluid(maxWidth: 215, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      tarmo: file(relativePath: { eq: "tarmo.png" }) {
        childImageSharp {
          fluid(maxWidth: 215, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      rafal: file(relativePath: { eq: "rafal-dyrda.png" }) {
        childImageSharp {
          fluid(maxWidth: 215, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  const quotes: QuoteItem[] = useMemo(
    () => [
      {
        logo: truck4goods,
        title: 'Build the “right thing” for customers',
        subtitle:
          "Develop a SaaS product that your customers will love. We probe deep into your customer's pains and how to solve them. From conducting user research to defining personas, exploring user flows, creating wireframes, and building high-fidelity prototypes ‒ we focus on customer outcomes to create an excellent user experience.",
        text: '“Their UI/UX skills have blown me away. They knew about everything from in-depth research to perfect execution. They were also patient and willing to walk us through their process while we learned.”',
        img: data.tarmo.childImageSharp.fluid,
        company: 'truck4goods',
        author: (
          <React.Fragment>
            <strong>Tarmo Maasel</strong> <br />
            Founder,{' '}
            <a href="https://truck4goods.com/" target="_blank" className={styles.red}>
              Truck4goods
            </a>
          </React.Fragment>
        ),
      },
      {
        logo: adact,
        title: 'Avoid common SaaS scaling pitfalls',
        subtitle:
          'Grow your customer base, and scale your SaaS cloud-based system to seamlessly handle millions of interactions. We’ve worked with dozens of fast-growth products and can help you go from 10,000 to well over 1 million concurrent users. Our secret? High-quality code, well-architected system design, robust infrastructure, and a plan for handling continuous growth.',
        text: '“We’ve tested the product and have acquired over two million interactions without experiencing downtime or bug-related issues, so I can confidently say that Brocorders has met our success criteria.”',
        img: data.kalev.childImageSharp.fluid,
        company: 'adact',
        author: (
          <React.Fragment>
            <strong>Kalev Kärpuk</strong> <br />
            CEO & Founder,{' '}
            <a href="https://adact.me/" target="_blank" className={styles.red}>
              Adact
            </a>
          </React.Fragment>
        ),
      },
      {
        logo: condogenie,
        title: 'Achieve long-term success',
        subtitle:
          'Focus on outcomes rather than output, and treat each project as your own ‒ that’s our motto. We take ownership of our projects and focus on producing superior products. We’re not about meeting deadlines at the expense of value – we’re about employing a product mindset to deliver real value to your customers – today and tomorrow.',
        text: '“Upoun launching the project we were able to increase client satisfaction and provide our clients what they were asking for, improve their workflow as well as make the software more powerful and easier to use then the previous software that was developed.”',
        img: data.rafal.childImageSharp.fluid,
        company: 'Condogenie',
        author: (
          <React.Fragment>
            <strong>Rafal Dyrda</strong> <br />
            CEO,{' '}
            <a href="https://condogenie.com/" target="_blank" className={styles.red}>
              Condogenie
            </a>
          </React.Fragment>
        ),
      },
    ],
    []
  );

  return (
    <section className="section">
      <div className="inner">
        {quotes.map((item: QuoteItem, idx: number) => (
          <div key={idx} className={styles.quote}>
            <div className={styles.quoteHeader}>
              <div>
                <img src={item.logo} className={styles.quoteCompanyLogo} alt={item.company} />
              </div>
              <h3 className={clsx('title', styles.quoteTitle)}>{item.title}</h3>
              <p className={clsx('subtitle', styles.quoteSubtitle)}>{item.subtitle}</p>
            </div>
            <Blockquote
              img={item.img}
              text={item.text}
              blockquoteClassName={styles.blockquote}
              author={item.author}
            />
          </div>
        ))}
      </div>
    </section>
  );
};

export default Quotes;
