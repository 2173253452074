import React, { FunctionComponent } from 'react';
import clsx from 'clsx';
import ExpandingList, { ListItem } from '../../expandinglist';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import styles from './saas.module.css';

import assemble from '../../../images/saas/integrations/assemble.png';
import spec from '../../../images/saas/integrations/spec.png';
import proto from '../../../images/saas/integrations/proto.png';
import control from '../../../images/saas/integrations/control.png';

type Item = {
  image: string;
  text: string;
  title: string;
};

const Integrations: FunctionComponent = () => {
  const data = useStaticQuery(graphql`
    query {
      arch: file(relativePath: { eq: "saas/integrations/arch.png" }) {
        childImageSharp {
          fluid(maxWidth: 800, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      maps: file(relativePath: { eq: "saas/integrations/maps.png" }) {
        childImageSharp {
          fluid(maxWidth: 800, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      maps_bottom: file(relativePath: { eq: "saas/integrations/maps2.png" }) {
        childImageSharp {
          fluid(maxWidth: 800, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      octa: file(relativePath: { eq: "saas/integrations/octa.png" }) {
        childImageSharp {
          fluid(maxWidth: 800, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      hubspot: file(relativePath: { eq: "saas/integrations/hubspot.png" }) {
        childImageSharp {
          fluid(maxWidth: 800, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      fitness: file(relativePath: { eq: "saas/integrations/fitness.png" }) {
        childImageSharp {
          fluid(maxWidth: 800, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  const list: ListItem[] = [
    {
      text: 'Using PayKickstart to design customized subscription logic and automate recurring billing',
      content: (
        <div className={styles.integrationContent}>
          <Img
            className={styles.integrationImg}
            fluid={data.arch.childImageSharp.fluid}
            alt="saas-integrations-arch"
          />
        </div>
      ),
    },
    {
      text: 'Switching from Google Maps to Mapbox to save 50% on the maps bill',
      content: (
        <div className={styles.integrationContent}>
          <Img
            className={styles.integrationAuth0}
            fluid={data.maps.childImageSharp.fluid}
            alt="saas-integrations-maps"
          />
          <Img
            className={styles.integrationAuth0}
            draggable={false}
            fluid={data.maps_bottom.childImageSharp.fluid}
            alt="saas-integrations-maps_bottom"
          />
          <p className={styles.integrationsHint}>
            As we can see using Mapbox saved us 50% total amount for maps using per month.
          </p>
        </div>
      ),
    },
    {
      text: 'Enabling lead management with a Hubspot integration',
      content: (
        <div className={styles.integrationContent}>
          <Img
            className={styles.integrationImg}
            fluid={data.hubspot.childImageSharp.fluid}
            alt="saas-integrations-hubspot"
          />
        </div>
      ),
    },
    {
      text: 'Integrating Auth0 (Okta) to improve the customer experience through seamless sign-on',
      content: (
        <div className={styles.integrationContent}>
          <Img
            className={styles.integrationAuth0}
            fluid={data.octa.childImageSharp.fluid}
            alt="saas-integrations-Okta"
          />
        </div>
      ),
    },
    {
      text: 'Connecting fitness equipment with end-user devices through health APIs',
      content: (
        <div className={styles.integrationContent}>
          <Img
            className={styles.integrationImg}
            fluid={data.fitness.childImageSharp.fluid}
            alt="saas-integrations-fitness"
          />
        </div>
      ),
    },
  ];

  const items: Item[] = [
    {
      image: assemble,
      title: 'Assemble the entire team in a couple of days',
      text: 'Get your SaaS development team built immediately.',
    },
    {
      image: spec,
      title: 'Write a maintainable SaaS specification',

      text: 'Ensure your SaaS product has required tech specifications when getting started.',
    },
    {
      image: proto,
      title: 'Get a high-fidelity prototype within a month',
      text: 'Reduce time-to-market and get early product adopters faster.',
    },
    {
      image: control,
      title: 'Stay in control with weekly reports and demos',
      text: 'Establish close communication with your team and follow the project’s progress.',
    },
  ];

  return (
    <div className="section">
      <div className="inner">
        <h2 className="title">Adding extra value to SaaS with third-party integrations</h2>

        <p className={clsx('subtitle', styles.integrationSubtitle)}>
          We can connect any third-party service with your product to add needed functionality and
          reduce the cost of software development. Check out some of our recent case studies:
        </p>
        <ExpandingList className={styles.expandingList} list={list} />
      </div>
      <div className="inner">
        <h2 className="title">Instant team setup and other niceties</h2>
        <p className="subtitle">Here are the perks you get when partnering with Brocoders.</p>
        <div className={styles.integrationItems}>
          {items.map((item: Item, idx: number) => (
            <div className={styles.integrationItem} key={idx}>
              <img src={item.image} draggable={false} alt={item.title} alt="" />

              <h4 className={styles.integrationTitle}>{item.title}</h4>
              <p className={styles.integrationText}>{item.text}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Integrations;
