import React, { FunctionComponent, ReactElement, useMemo } from 'react';
import clsx from 'clsx';
import Img from 'gatsby-image';

import styles from './saas.module.css';
import { graphql, useStaticQuery } from 'gatsby';
import { ImageFluid } from '../../Types';

type Item = {
  image: ImageFluid;
  className?: string;
  text: string | ReactElement;
};

const Solutions: FunctionComponent = () => {
  const images = useStaticQuery(graphql`
    query {
      app: file(relativePath: { eq: "saas/solutions/app.png" }) {
        childImageSharp {
          fluid(maxWidth: 300, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      main: file(relativePath: { eq: "saas/solutions/screen.png" }) {
        childImageSharp {
          fluid(maxWidth: 800, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      aws: file(relativePath: { eq: "saas/solutions/aws.png" }) {
        childImageSharp {
          fluid(maxWidth: 300, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      cloud: file(relativePath: { eq: "saas/solutions/cloud.png" }) {
        childImageSharp {
          fluid(maxWidth: 300, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      curve: file(relativePath: { eq: "saas/solutions/curve.png" }) {
        childImageSharp {
          fluid(maxWidth: 300, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      data: file(relativePath: { eq: "saas/solutions/data.png" }) {
        childImageSharp {
          fluid(maxWidth: 300, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      integrations: file(relativePath: { eq: "saas/solutions/integrations.png" }) {
        childImageSharp {
          fluid(maxWidth: 300, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      mern: file(relativePath: { eq: "saas/solutions/mern.png" }) {
        childImageSharp {
          fluid(maxWidth: 300, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      notification: file(relativePath: { eq: "saas/solutions/notification.png" }) {
        childImageSharp {
          fluid(maxWidth: 300, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      payments: file(relativePath: { eq: "saas/solutions/payments.png" }) {
        childImageSharp {
          fluid(maxWidth: 300, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      saas: file(relativePath: { eq: "saas/solutions/saas.png" }) {
        childImageSharp {
          fluid(maxWidth: 300, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      screen: file(relativePath: { eq: "saas/solutions/screen.png" }) {
        childImageSharp {
          fluid(maxWidth: 300, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      secure: file(relativePath: { eq: "saas/solutions/secure.png" }) {
        childImageSharp {
          fluid(maxWidth: 300, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  const items: Item[] = useMemo(
    () => [
      {
        image: images.saas.childImageSharp.fluid,
        className: styles.saas,
        text: (
          <div>
            <div>SaaS main roles and entities:</div>
            <div>- user profile</div>
            <div>- company profile</div>
            <div>- etc</div>
          </div>
        ),
      },
      {
        image: images.cloud.childImageSharp.fluid,
        className: styles.cloud,
        text: 'Defense mechanisms for data protection',
      },
      {
        image: images.secure.childImageSharp.fluid,
        className: styles.secure,
        text: 'Multi-factor authentication mechanisms',
      },
      {
        image: images.notification.childImageSharp.fluid,
        className: styles.notification,
        text: 'Notifications system',
      },
      {
        image: images.integrations.childImageSharp.fluid,
        className: styles.integrations,
        text: 'Advanced API integrations',
      },
      {
        image: images.data.childImageSharp.fluid,
        className: styles.data,
        text: 'Custom data visualization tools and dashboards',
      },
      {
        image: images.curve.childImageSharp.fluid,
        className: styles.curve,
        text: 'White-label admin panel made by Brocoders',
      },
      {
        image: images.payments.childImageSharp.fluid,
        className: styles.payments,
        text: 'Custom pricing and advanced SaaS billing systems',
      },
    ],
    [images]
  );

  const techs: Item[] = useMemo(
    () => [
      {
        image: images.app.childImageSharp.fluid,
        text: 'Cost-effective multi-tenant architecture',
      },
      {
        image: images.aws.childImageSharp.fluid,
        text: 'AWS to properly architect, build, and scale your SaaS app',
      },
      {
        image: images.mern.childImageSharp.fluid,
        text: 'MERN stack for faster application development',
      },
    ],
    [images]
  );

  return (
    <section className={clsx('section', styles.gray)}>
      <div className="inner">
        <h2 className="title">Proficient in delivering SaaS solutions</h2>
        <p className="subtitle">
          Enable fast application development using JavaScript-based technologies. Host your app in
          the cloud and pay only for what you need. Enhance your product with third-party
          integrations. Shield your data from cybercriminals. We know how to build SaaS.
        </p>

        <div className={styles.solutions}>
          <div className={styles.screenWrapper}>
            <Img
              className={styles.screen}
              fluid={images.main.childImageSharp.fluid}
              alt="screen"
              draggable={false}
            />
            {items.map((item: Item, idx: number) => (
              <div className={clsx(styles.item, item.className)} key={idx}>
                <Img
                  fluid={item.image}
                  className={styles.solutionsImg}
                  draggable={false}
                  alt={`solution-img-${idx}`}
                />
                <p className={styles.itemText}>{item.text}</p>
              </div>
            ))}
          </div>
        </div>
        <div className={styles.techs}>
          {techs.map((item: Item, idx: number) => (
            <div className={styles.tech}>
              <div className={styles.techImageWrapper}>
                <Img fluid={item.image} alt={`solutions-tech-${idx}`} />
              </div>
              <p className={styles.techText}>{item.text}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Solutions;
